import { environment } from './../../environments/environment';
import { WineItem } from '../entities/wine';
import { Component, OnInit, Input } from '@angular/core';
import { ImgDownloaderService } from '../img-downloader.service';
import { saveAs as importedSaveAs } from "file-saver";

@Component({
  selector: 'app-wine-item',
  templateUrl: './wine-item.component.html',
  styleUrls: ['./wine-item.component.scss']
})
export class WineItemComponent implements OnInit {

  @Input() wine: WineItem;

  apiUrl: string;

  placeholder = {

    description: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod #
   tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At`,
    alcohol: 99.99,
    acid: 99.99,
    year: 2099,
    imageUrl: '/uploads/e8a972dc4bb145c1b2fb06fdba2e8f6b.jpg'
  };


  constructor(private imgDownloader: ImgDownloaderService) {
    this.apiUrl = environment.apiUrl;
  }

  ngOnInit() {
  }

  downloadBottleImage(url: string, name: string) {
    console.log(url);
    this.imgDownloader.getImage(url).subscribe(blob => {
      importedSaveAs(blob, name);
    }
    );
  }

  getName(): string {
    let organic = "";
    if(this.wine.organic)
      organic = " Bio"
    return  this.wine.name + organic;
  }

}
